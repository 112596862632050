import {
	FETCH_AUDIT_LOGS_LOADER,
	FETCH_AUDIT_LOGS,
	FETCH_AUDIT_LOGS_ERROR,
	TEST_PAGE,
	TEST_PAGE_ERROR,
	OPEN_MODAL,
	CLOSE_MODAL,
} from "../actions/types";

const audit = (state = { loader: false }, action) => {
	switch (action.type) {
		case OPEN_MODAL:
			console.log("open modal", { ...state, open_modal: true });
			return { ...state, open_modal: true };
		case CLOSE_MODAL:
			return { ...state, open_modal: false };
		case FETCH_AUDIT_LOGS_LOADER:
			return { ...state, loader: true };
		case FETCH_AUDIT_LOGS:
			return { ...state, logs: action.payload, loader: false };
		case FETCH_AUDIT_LOGS_ERROR:
			return { ...state, fetchLogsErrorResponse: action.payload, loader: false };
		case TEST_PAGE:
			return { ...state, test_result: action.payload, test_result_error: false };
		case TEST_PAGE_ERROR:
			return { ...state, test_result_error: action.payload, test_result: false };
		default:
			return state;
	}
};

export default audit;
